@font-face {
    font-family: 'ClientFontBold';
    src: local('ClientFontBold'), url(./Assets/Fonts/client/Inter-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'ClientFontMedium';
    src: local('ClientFontMedium'), url(./Assets/Fonts/client/Inter-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'ClientFontRegular';
    src: local('ClientFontRegular'), url(./Assets/Fonts/client/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'CelebrationFontBold';
    src: local('ClientFontBold'), url(./Assets/Fonts/GillSans/GillSans-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'CelebrationFontRegular';
    src: local('ClientFontRegular'), url(./Assets/Fonts/GillSans/GillSans-Regular.ttf) format('truetype');
}
